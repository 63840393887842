import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";


export default function AffBridge() {

    
  const { referal } = useParams();

    useEffect(() => {
        console.log(referal);
        localStorage.setItem("_referal", referal);
        window.location.href = "https://adclair.com";
    },  [referal]);

    return (
        <div>
            
        </div>
    )
}