import React, { useEffect, useState } from 'react';
import { TableContextProvider, useTableContext } from '../../context/TableContext';
import SyncProductsTable from '../../components/table/SyncProductsTable';
import API from '../../utils/api';
import Button from '../../components/Button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import { Tooltip } from "react-tooltip";
import toast from 'react-hot-toast';
import {
  imagyTextBodyTemplate,
  linkBodyTemplate,
  statusBodyTemplate,
} from "../../components/table/Cells";

import ProductPreview from './Shopify/ProductPreview';

const Products = ({ data, handleContinue, domains, platform }) => {
  const [localData, setLocalData] = useState(
    data.map((item) => ({
      ...item,
      variants: item.variants.map((variant) => ({
        ...variant,
        cost: variant.cost || "",
      })),
    }))
  );
  const [previewProduct, setPreviewProduct] = useState(null);
  const {
    selectedItems,
    setSelectedItems
  } = useTableContext();

  useEffect(() => {
    const updatedSelectedItems = selectedItems.map((selectedItem) => {
      const matchingLocalData = localData.find((dataItem) => dataItem.id === selectedItem.id);
      if (matchingLocalData) {
        return {
          ...selectedItem,
          variants: matchingLocalData.variants.map((variant, index) => ({
            ...variant,
            cost: variant.cost,
          })),
        };
      }
      return selectedItem;
    });

    setSelectedItems(updatedSelectedItems);
  }, [localData]);



  const handleUpdateCost = (id, newValue) => {
    const updatedData = localData.map((item) => item.id === id ? newValue : item);
    setLocalData(updatedData);
    // const updatedSelectedProducts = selectedItems.map((item) => item.id === id ? newValue : item);
    // setSelectedItems(updatedSelectedProducts);
  };

  const EditableCostBodyTemplate = (rowData) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(rowData.variants[0]?.cost || "");
    const handleClick = () => {
      if (rowData.variants.length > 1) {
        setPreviewProduct(rowData);
      } else {
        setIsEditing(true);
      }
    };
  
    const updateCost = () => {
      const updatedData = localData.map((item) =>
        item.id === rowData.id
          ? { ...item, variants: [{ ...item.variants[0], cost: newValue }] }
          : item
      );
      setLocalData(updatedData);
      setIsEditing(false);
    };
  
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        updateCost();
      } else if (e.key === "Escape") {
        setIsEditing(false);
      }
    };
  
    return (
      <div>
        {!isEditing ? (
          <div
            className="flex items-center gap-2 justify-between cursor-pointer"
            onClick={handleClick}
          >
            {rowData.variants.length > 1 ? (
              <span className="font-bold">Multi Variant</span>
            ) : (
              <span className="font-bold">{`${rowData.variants[0]?.price} / ${
                rowData.variants[0]?.cost || " - "
              }`}</span>
            )}
            <Icon name="Pencil" size="16" />
          </div>
        ) : (
          <div className="flex items-center gap-2 w-[170px]">
            <Input
              sizing="sm"
              type="number"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className="flex gap-2 items-center cursor-pointer">
              <div onClick={updateCost}>
                <Icon name="Save" color="#5a5c73" size="16" />
              </div>
              <div onClick={() => setIsEditing(false)}>
                <Icon name="X" color="#F5432C" size="16" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleProductsImport = async () => {
    try {

      const productsWithPlatform = selectedItems.map(product => ({
        ...product,
        platform: platform,
        rowProduct: product,
      }));
  
      const response = await API.post("/addProducts", { 
        products: productsWithPlatform,
        storeName: domains[0],
      });
  
      if (response.status === 201) {
        // toast.success("Products imported successfully");
        handleContinue();
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };
  
  return (
    <>
      <div className="my-3 rounded mx-4 shadow">
        <DataTable
          value={localData}
          stripedRows
          showGridlines
          paginator
          rows={7}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="No available data"
          selection={selectedItems}
          onSelectionChange={(e) => setSelectedItems(e.value)}
          dataKey="id"
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
          <Column field="product" header="Product" body={(rowData) => imagyTextBodyTemplate(rowData.title, rowData.coverImage)} />
          <Column field="cost" header="Price / Cost" body={EditableCostBodyTemplate} />
          <Column field="status" header="Status" body={(rowData) => statusBodyTemplate(rowData.status)} />
        </DataTable>
        {previewProduct && <ProductPreview product={previewProduct} setPreviewProduct={setPreviewProduct} handleUpdateCost={handleUpdateCost} />}
      </div>
      <div className="flex flex-col justify-center gap-4 mb-2 w-full px-4">
        <div className="flex flex-col gap-4">
          <Button
            value="Import Products"
            onClick={handleProductsImport}
            className="bg-[#1972F5] py-2 px-9 rounded-lg text-white w-80 mx-auto"
          />
        </div>
      </div>
    </>
  );
};



const ProductTable = ({ shopifyProducts, handleContinue, domains, platform}) => {
  
  return (
    <div className="w-full max-w-4xl px-4">
      <h1 className="text-4xl font-semibold text-gray-900 mb-2 text-center">
        Import Products from Your Store
      </h1>
      <h3 className="text-gray-600 mb-9 text-center">
        Browse and select products from your store to add them to your inventory
      </h3>
      <TableContextProvider>
        <Products
          data={shopifyProducts}
          handleContinue={handleContinue}
          domains={domains}
          platform={platform}
        />
      </TableContextProvider>
    </div>
  );
};

export default ProductTable;
