import React, { useState } from 'react';
import { validShopifyDomain } from '../../../utils/helper';
import API from '../../../utils/api';
import Button from '../../../components/Button';
import Input from "../../../components/Input";
import { getProductsByStoreId } from '../../../utils/services';
import { MdStorefront } from "react-icons/md";
import { useNavigate } from 'react-router';
import { createIntegration } from '../../../utils/services';

const Install = ({ setDomains, domains, loading, setLoading, setProducts, handleContinue }) => {
    const platform = "Youcan" 
    const [storeId, setStoreId] = useState(null);

    // PENDING, INSTALLED
    const [installState, setInstallState] = useState(null);
    const navigate = useNavigate();

    const isValid = (storeUrl) => validShopifyDomain(storeUrl);

    const goToInstall = (storeUrl) => {

        // TODO: use process.env.REACT_APP_YC_INSTALL_LINK
        // let link = `https://seller-area.youcan.shop/admin/oauth/authorize?client_id=318&redirect_uri=http://localhost:3000/auth/callback&response_type=code&scope[]=*`;
        
        let link = "https://seller-area.youcan.shop/admin/oauth/authorize?scopes=%2A&response_type=code&client_id=318&redirect_uri=https%3A%2F%2Fseller-area.youcan.shop%2Fadmin%2Fapps%2Fauthorization-callback%2Fapp_2o9iSflAyMYyGme3bMulaxyzSK7"
        window.open(link, '_blank');
        setInstallState('PENDING');
    };

    const handleCheck = async () => {
        setLoading(true);
        const storeUrl = domains[0];
        try {
            const { data: storeData } = await API.get(`/store`, { params: { shop: storeUrl, platform } });
            if (storeData?.ownerId) {
                setStoreId(storeData._id);
                const { data } = await getProductsByStoreId(storeData.storeId, platform);
                setProducts(data);
                setInstallState('INSTALLED');
                const integrationData = {
                    platform,
                    storeUrls: domains,
                    storeId: storeData.storeId,
                };
                await createIntegration(integrationData);
                handleContinue();
            } else {
                setInstallState('PENDING');
            }
        } catch (error) {
            console.log(error);
            setStoreId(null);
        } finally {
            setLoading(false);
        }
    };
    

    const handleDomainChange = (index, e) => {
        const value = e.target.value.replace(/^https?:\/\//, '');
        const newDomains = [...domains];
        newDomains[index] = `${value}`;
        setDomains(newDomains);
    };

    const openTuto = () => {
        window.open("https://youtu.be/_uOJOQNOqrs", "_blank")
      }
    return (
        <div className="w-full max-w-4xl px-4">
            <h1 className="text-4xl font-semibold text-gray-900 mb-2 text-center">Enter your store name</h1>
            <h3 className="text-gray-600 mb-9 text-center">This helps us to link your store with this integration</h3>
            {
                installState &&
                <form className="flex flex-col items-center">
                    {domains.map((domain, index) => (
                         <div  key={index} className='w-full mb-6' >
                            <Input
                            value={domain.replace(/^https?:\/\//, '')}
                            onChange={(e) => handleDomainChange(index, e)}
                            placeholder="my-youcan-store-name"
                            label="Youcan store name"
                            //    disabled={storeId}
                            icon={MdStorefront }
                            />
                         <div onClick={openTuto} className="text-blue ml-2 mt-1 text-[12px] underline cursor-pointer">How to find my store name ?</div>
                       </div>
                    ))}
                </form>
            }

            <div className="flex flex-col gap-2">
                {!installState && <Button value="Install app " before={false} icon={{name:"ExternalLink", color: "white"}} onClick={goToInstall} loading={loading} />}
                {installState === "PENDING" && <Button value="Check connection" onClick={handleCheck} loading={loading} />}
                <button className="py-2 px-9 rounded-lg w-full" onClick={() => navigate("/integration")}>Cancel</button>
            </div>
        </div>
    );
};

export default Install;
