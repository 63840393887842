import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { removeToken, removeUser } from "../utils/localStorage";
import useUserPlan from "../hooks/useUserPlan";
import EarlyBirdPlan from "../components/plans/earlyBird";
import FlashPlan from "../components/plans/flashPlan";
import { Crown } from "lucide-react";

const ToggleButton = ({ isToggled, onToggle }) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      <span
        className={`transition-colors duration-300 ${
          !isToggled ? "font-bold text-blue" : "text-gray"
        }`}
      >
        Early Bird
      </span>
      <div
        onClick={onToggle}
        className="relative w-14 h-7 rounded-full cursor-pointer transition-colors duration-300 flex items-center border border-gray"

      >
        <div
          className={`absolute w-6 h-6 bg-blue rounded-full transition-transform duration-300 ${
            isToggled ? "translate-x-7" : "translate-x-1"
          }`}
        ></div>
      </div>
      <span
        className={`transition-colors duration-300 ${
          isToggled ? "font-bold text-blue" : "text-gray"
        }`}
      >
        Entrepreneur
      </span>
    </div>
  );
};

const PayPalSubscription = () => {
  const navigate = useNavigate();
  const [referral, setReferral] = useState();
  const { userPlanLoading } = useUserPlan();
  const [isEntrepreneurPlan, setIsEntrepreneurPlan] = useState(false);

  const handleLogout = () => {
    removeToken();
    removeUser();
    navigate("/login");
  };

  const handleContactUs = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("_referal")) {
      setReferral(!!localStorage.getItem("_referal"));
    } else {
      setReferral(false);
    }
  }, []);

  return (
    <div className="min-h-screen w-full flex flex-col relative">
      <div className="absolute inset-0">
        <img src="/images/preview.png" className="h-full w-full object-cover blur-sm" />
        <div className="absolute inset-0 bg-[#0000002e]" />
      </div>

      <div className="relative flex flex-col flex-grow justify-center items-center p-4">
        {userPlanLoading ? (
          <div className="flex flex-col items-center">
            <div className="w-[100px] rounded">
              <img src="images/logos/logo-black-short.jpeg" className="w-[100px] rounded" />
            </div>
            <div>Loading...</div>
          </div>
        ) : (
          <>
            <div className="shadow-lg w-full md:w-[800px] rounded-lg bg-white p-4 flex flex-col items-center justify-center text-center space-y-5">
              <div
                className="bg-[#f2f3ff] text-[#0072FF] flex items-center justify-center rounded-full"
                style={{ width: "45px", height: "45px" }}
              >
                <Crown size={25} />
              </div>

              <div className="space-y-2">
                <h1 className="text-2xl font-bold">Upgrade to Premium</h1>
                <p className="text-sm text-gray">
                  Get access to all features. Please choose one of the paid plans to continue.
                </p>
              </div>

              <div className="md:hidden mb-4">
                <ToggleButton
                  isToggled={isEntrepreneurPlan}
                  onToggle={() => setIsEntrepreneurPlan(!isEntrepreneurPlan)}
                />
              </div>

              <div className="hidden md:flex justify-center space-x-16">
                <EarlyBirdPlan />
                <FlashPlan />
              </div>

              <div className="md:hidden w-full">{isEntrepreneurPlan ? <FlashPlan /> : <EarlyBirdPlan />}</div>

              <div className="flex flex-col items-center justify-center">
                <div className="mt-6 text-gray-600 text-sm text-center">
                  Need help or want to log out? Choose an option below:
                </div>
                <div className="flex gap-2 cursor-pointer mt-2">
                  <div onClick={handleLogout}>Logout</div>
                  <div>|</div>
                  <div onClick={handleContactUs}>Contact us</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PayPalSubscription;


