import React from "react";
import Button from "../components/Button";
import Preview from "../components/previews/Preview";
import { ReactComponent as Empty } from '../svgs/empty.svg';

const Page = ({
  title,
  subtitle,
  actions = [],
  children,
  fullWidth = true,
  empty = false
}) => {

  // console.log("empty", empty)

  if (empty) {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center"> 
            <div className="text-center max-w-3xl mx-auto">
              <div className="mb-8">
                <Empty className="mx-auto" />
              </div>
              <h1 className="text-2xl font-bold mb-4">
                Next Step: Set up a data stream to start collecting data 
              </h1>
              <h3 className="text-gray-600 mb-8">
                After creating a data stream, you'll get tagging information
              </h3>
            </div>
            <div>
              {actions.map(({ action, name, type, icon }, index) => {
              return (
                <Button
                  key={index}
                  onClick={action}
                  value={name}
                  type={type}
                  icon={icon}
                  className="bg-[#1972F5] hover:bg-blue-600 text-white font-medium py-2 px-6 rounded-lg transition duration-200"
                />
              );
            })}
            </div>
            {children}
        </div>
      );
  }
  return (
    <div className={`h-screen ${fullWidth ? "" : "max-w-[1000px] m-auto"}`}>
      <div className="p-3 flex flex-col gap-7 w-full">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1">
            <h1 className=" text-2xl font-bold leading-10">{title}</h1>
            {subtitle && <p className="text-sm ">{subtitle}</p>}
          </div>
          
          <div className="flex items-center justify-center gap-4">
            {actions.map(({ action, name, type, icon }, index) => {
              return (
                <Button
                  key={index}
                  onClick={action}
                  value={name}
                  type={type}
                  icon={icon}
                />
              );
            })}
          </div>
        </div>
        {children}
        {/* <div className="flex flex-col ">
          <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-black-100">
            {" "} If you have any questions or need assistance {" "}
            <a onClick={handleContactUs}  className="text-[#0D6EFD] cursor-pointer" >
              Contact Us
            </a>
          </p>
        </div> */}
        <Preview />
      </div>
    </div>
  );
};

export default Page;
