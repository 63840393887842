import React, { useState } from "react";
import { Menu } from "lucide-react";


const NavbarV2 = ({ toggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
    toggleSidebar(!isOpen);
  };

  return (
    <div className="flex w-full py-2 px-4 items-center bg-[#FFFFFF] justify-between fixed shadow-sm">
      <div className="flex items-center gap-2">
        <div className="cursor-pointer" onClick={handleToggleSidebar}>
          <Menu size={24} />
        </div>
        <img src="images/logos/logo-black-short.jpeg" alt="Logo" className="h-8" />
        <img src="images/logo-black-long.png" alt="Logo" className="h-8"/>
      </div>
    </div>
  );
};

export default NavbarV2;
