import React, { useEffect, useState } from "react";
import { TableContextProvider } from "../context/TableContext";

import Page from "./Page";
import { adConfiguration as tableConfiguration } from "../constant/tablesParam";
import AdAccountPreview from "../components/previews/AdAccountPreview";
import LinkAccountModal from "../components/modals/LinkAccountModal";
import { useAppContext } from "../context/Context";
import { useErrorContext } from "../context/ErrorContext";
import AdAccountsTable from "../components/table/AdAccountsTable";
import useAdAccounts from "../hooks/useAdAccounts";
import LinkAccountModalV2 from "../components/modals/LinkAccountModalV2";
import { useLocation, useParams } from "react-router";
import Loading from "../components/Loading";

const getPlatformCounts = (adAccounts) => {
  return adAccounts.reduce((counts, account) => {
    const platform = account.platform; 
    counts[platform] = (counts[platform] || 0) + 1;
    return counts;
  }, {});
};

const Ad = () => {
  const { userData } = useAppContext();
  const adAccountsAction = useAdAccounts()
  const { platform } = useParams();
  const [isEmptyPage, setIsEmptyPage] = useState(true);
  const [platformCounts, setPlatformCounts] = useState({});

  const [adAccountsState, setAdAccountsState, fetchAdAccounts] = adAccountsAction;
  const [showModal, setShowModal] = useState(false);
  const [showAssignAdPreview, setShowAssignAdPreview] = useState(false);
  const { setStatus} = useErrorContext()


  const handleOpenModal = () => {
    setPlatformCounts(getPlatformCounts(adAccountsState.data));
    setShowModal(true)
  }

  const actions = [
    {
      name: "Link Account",
      action: () => handleOpenModal(),
      type: "primary",
      icon: {
        name: "Link",
        color: "white",
      },
    },
  ];

  const emptyState = {
    actions,
    title: "Link your first FB Account",
    subtitle:
      "Connect a Facebook profile with admin access to your ad account to start",
    image: "link.png",
  };



  useEffect(() => {
    if (platform === "tiktok" || platform === "google" || platform === "snapchat") {
      setShowModal(true);
    }
  }, [platform]);



  // const fetchAdAccounts = async () => {
  //   if (userData.role === "admin") {
  //     try {
  //       setStatus(true)
  //       const { data } = await Api.get("/adaccounts");
  //       console.log(data.adAccounts);
  //       setData(data.adAccounts);
  //     } catch (error) {
  //       // TODO: handle error
  //       console.log(error, "error dataaa");
  //       if(error.response.status === 500) {
  //       setStatus(false)
  //     }
  //     }
  //   } else {
  //     try {
  //       setStatus(true)
  //       const data = await Api.get("/getAdAccountsByPublisher");
  //       setData(data.data.adAccounts);
  //     } catch (error) {
  //       // TODO: handle error
  //       console.log(error, "error dataaa");
  //       if(error.response.status === 500) {
  //       setStatus(false)
  //     }
  //     }
  //   }
  // };

  useEffect(() => {
    // if (adAccountsState?.data && adAccountsState.data.length === 0) {
    //   setIsEmptyPage(true);
    // } else {
    //   setIsEmptyPage(false);
    // }
    if (!adAccountsState.loading) {
      setIsEmptyPage(adAccountsState?.data?.length === 0);
    }
  }, [adAccountsState]);


  
  const tableActions = [
    {
      name: "Assigne",
      action: () => setShowAssignAdPreview(true),
      type: "secondary",
      icon: { name: "GitPullRequestCreateArrow" },
    },
    {
      name: "Delete",
      action: () => console.log,
      type: "danger",
      icon: { name: "Trash" },
    },
  ];

  const closePreview = () => {
    setShowAssignAdPreview(false);
  };

  
  
  if (adAccountsState.error) {
    return <div>Error loading ad accounts: {adAccountsState.error}</div>;
  }

  
  if (adAccountsState.loading) {
    return <Loading />;
  }

  return (
    <Page title="Ad Accounts" actions={actions} empty={isEmptyPage}>
      <TableContextProvider
        {...{
          tableConfiguration,
          emptyState,
          selectable: userData.role === "admin" ? true : false,
          actions: tableActions,
        }}
      >
        {/* <TableContainer title={"Accounts"} /> */}
        {!isEmptyPage && (<AdAccountsTable adAccountsState={adAccountsState} setAdAccountsState={setAdAccountsState} />)}
        {/* <AdAccountsTable adAccountsAction={adAccountsAction} /> */}
        {/* {showModal && <LinkAccountModal showModal={showModal} setShowModal={setShowModal} fetchAdAccounts={fetchAdAccounts}/>} */}
        {showModal && <LinkAccountModalV2 platformCounts={platformCounts} showModal={showModal} setShowModal={setShowModal} fetchAdAccounts={fetchAdAccounts}/>}
        {/* <AssigneAddPreview
          showAssignAdPreview={showAssignAdPreview}
          closePreview={closePreview}
          fetchAdAccounts={fetchAdAccounts}
        />*/}
        <AdAccountPreview fetchAdAccounts={fetchAdAccounts} /> 
      </TableContextProvider>
    </Page>
  );
};

export default Ad;
