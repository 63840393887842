import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { handleLogin } from "../../utils/helper";
import Api from "../../utils/api";

import SyncAdAccountsTable from "../table/SyncAdAccountsTable"
import { TableContextProvider } from "../../context/TableContext";
import { useTableContext } from "../../context/TableContext";
import { useErrorContext } from "../../context/ErrorContext";
import { defaultFetchState } from "../../utils/services";
import { ReactComponent as Facebook} from "../../svgs/AdAccounts/facebook.svg";
import { ReactComponent as Google} from "../../svgs/AdAccounts/google.svg";
import { ReactComponent as SnapChat} from "../../svgs/AdAccounts/snapchat.svg";
import { ReactComponent as TikTok} from "../../svgs/AdAccounts/tiktok.svg";
import { Plug, CheckCircle2 } from "lucide-react";
import Icon from "../Icon";
import { useLocation, useNavigate, useParams } from "react-router";
import Button from "../Button";



const styles = {
    container: "max-w-[1200px] mx-auto p-6 bg-white rounded-lg shadow-lg",
    header: "mb-6",
    title: "text-2xl font-bold mb-2",
    subtitle: "text-gray",
    cardGrid: "grid grid-cols-2 gap-4",
    card: "bg-white rounded-lg p-6 cursor-pointer transition-all duration-200 hover:shadow-md",
    logoContainer: "flex items-center gap-3 mb-3 mt-4",
    platformName: "font-semibold",
    statusContainer: "flex items-center gap-2 text-sm text-gray mb-6",
  };

const PlatformData = {
    Facebook: {
      icon: <Facebook className="w-8 h-8" />,
      linkedAccounts: 0
    },
    Snapchat: {
      icon: <SnapChat className="w-8 h-8" />,
      linkedAccounts: 0
    },
    TikTok: {
      icon: <TikTok className="w-8 h-8" />,
      linkedAccounts: 0
    },
    Google: {
      icon: <Google className="w-8 h-8" />,
      linkedAccounts: 0
    }
  };

  const PlatformCard = ({ name, action, loadingPlatform }) => {
    const platform = PlatformData[name];

    const handleClick = () => {
        action(name);
    };

    const isLoading = loadingPlatform === name;

    return (
        <div className={`${styles.card} border ${styles.cardUnselected}`}>
            <div className={styles.logoContainer}>
                {platform.icon}
                <span className={styles.platformName}>{name}</span>
            </div>

            <div className={styles.statusContainer}>
                {platform.linkedAccounts > 0 ? (
                    <>
                        <CheckCircle2 className="w-4 h-4 text-green" />
                        <span>{platform.linkedAccounts} accounts linked</span>
                    </>
                ) : (
                    "No Accounts Connected"
                )}
            </div>

            <Button
                value={isLoading ? "Loading..." : "Connect"}
                className="w-full bg-[#0D6EFD] disabled:bg-gray-700 text-white text-center font-medium text-[16px] leading-6 rounded-lg"
                onClick={handleClick}
                loading={isLoading} 
            />
        </div>
    );
};



const AdAccountsListModal = ({showModal, setShowModal, onClose, handlePlatformLogin, loadingPlatform, platformCounts}) => {
    const platformNames = ["Facebook", "TikTok", "Google", "Snapchat"];
    
    platformNames.forEach((name) => {
      const lowerCaseName = name.toLowerCase();
      const count = platformCounts && platformCounts[lowerCaseName] !== undefined 
        ? platformCounts[lowerCaseName] 
        : 0;
      PlatformData[name].linkedAccounts = count;
    });
    

    return (
        <div
          className={` ${showModal ? "fixed" : "hidden"
            } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
        >
            <div className={styles.container}>
            <button
                onClick={() => {
                  onClose?.();
                  setShowModal(false);
                }}
              >
                <Icon name="X" />
              </button>

            <div className={styles.header}>
                <h2 className={styles.title}>
                Connect your ad accounts and import campaigns
                </h2>
                <p className={styles.subtitle}>
                All your campaigns from your ad accounts.
                </p>
            </div>
            
            <div className={styles.cardGrid}>
                {platformNames.map(name => (
                <PlatformCard
                    key={name}
                    name={name}
                    action={handlePlatformLogin}
                    loadingPlatform={loadingPlatform}
                />
                ))}
            </div>
            </div>
        </div>
      );
}

const LinkAccountModalV2 = ({ setShowModal, showModal, fetchAdAccounts, platformCounts }) => {
    
    const [account, setAccount] = useState(null);
    const [adAccountsState, setAdAccountsState] = useState({ ...defaultFetchState, data: null });
    const { platform } = useParams();
    const { search } = useLocation()
    const [code, setCode] = useState(null);
    const [loadingPlatform, setLoadingPlatform] = useState(null);
    const navigate = useNavigate();

    console.log("platformCounts", platformCounts)

    useEffect(() => {
      if (platform === "tiktok") {
        setLoadingPlatform("TikTok");
        const params = new URLSearchParams(search);
        const code = params.get("code");

        if (code) {
          setCode(code);
          const handleCodeExchange = async () => {
            try {
              setAdAccountsState((prevState) => ({
                ...prevState,
                loading: true,
              }));
            
              const { data: adAccountsData } = await Api.post("/account", {
                code: code,
                status: "active",
                platform: "tiktok", 
              });
              setAccount(adAccountsData.authResponse)
              setAdAccountsState((prevState) => ({
                ...prevState,
                data: adAccountsData.adAccounts,
                loading: false,
              }));
              setLoadingPlatform(null);
            } catch (e) {
              console.error("Error during code exchange:", e);
            }
          };
          handleCodeExchange();
        }
      }
    }, [platform, search]); 


    const saveAccount = async ({ accessToken, userID }) => {
        try {
          setAdAccountsState({
            ...adAccountsState,
           loading: true,
          });
          const { data: adAccountsData } = await Api.post("/account", {
            accountUserId: userID,
            accessToken: accessToken,
            status: "active",
            platform: "facebook",
          });
          setAdAccountsState({
            ...adAccountsState,
            data: adAccountsData,
            loading: false,
          });
        } catch (e) {
          setAdAccountsState({
            data: null,
            loading: false,
            error: e,
          });
        }
      };

    const loginCallback = (response) => {
        setAccount(response);
        if (response.status === "connected") {
          saveAccount(response.authResponse);
        }
        setLoadingPlatform(null);
      }; 
    
    const handleFacebookLogin = () => {
        setLoadingPlatform("Facebook")
        handleLogin(loginCallback);
    }

    const handleGoogleLogin = () => {
        console.log("Google Login");
    }

    const handleTikTokLogin = () => {
        setLoadingPlatform("TikTok")
        const app_id = process.env.REACT_APP_TT_APP_ID;
        const redirectUri = process.env.REACT_APP_TT_CALLBACK_URL;
        const authUrl = `https://business-api.tiktok.com/portal/auth?app_id=${app_id}&state=your_custom_params&redirect_uri=${redirectUri}`;
        window.location.href = authUrl;
    }

    const handleSnapchatLogin = () => {
        console.log("Snapchat Login");
    }

    const handlePlatformLogin = (platformName) => {
        switch (platformName) {
            case "Facebook":
                handleFacebookLogin();
                break;
            case "Google":
                handleGoogleLogin();
                break;
            case "TikTok":
                handleTikTokLogin();
                break;
            case "Snapchat":
                handleSnapchatLogin();
                break;
            default:
                console.log("Unknown platform");
        }
    }

    useEffect(() => {
        if (window.FB) {
          window.FB.getLoginStatus(function (response) {
            // console.log(response);
            const {  status } = response;
            if (status === "connected") {
              setAccount(response);
            } else {
              setAccount(response);
            }
          });
        }
      }, []);


    const handleClose = () => {
        setShowModal(false);
        navigate("/ad");
    } 

    return (
        <>
        {!adAccountsState.data ? 
        (<AdAccountsListModal 
            showModal={showModal} 
            setShowModal={setShowModal} 
            onClose={handleClose} 
            handlePlatformLogin={handlePlatformLogin}
            loadingPlatform={loadingPlatform}
            platformCounts={platformCounts}
        />) : (
            <TableContextProvider
              {...{
                selectable: true
              }}
            >
              <AdAccountsList {...{ showModal, onClose: handleClose, setShowModal, account, fetchAdAccounts, adAccountsState }} />
            </TableContextProvider>
          )}

        </>
    );
};


export default LinkAccountModalV2;

const AdAccountsList = ({ showModal, onClose, setShowModal, account , fetchAdAccounts, adAccountsState}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedItems } = useTableContext();
    const { setStatus} = useErrorContext()
    const navigate = useNavigate();



  const handleSave = async () => {
    const accountUserId = account?.authResponse?.userID || account?.userID;
    try {
      setStatus(true)
      setIsLoading(true);
      await Api.post(`/adaccount`, {
        adAccounts: selectedItems,
        accountUserId: accountUserId,
      });
      fetchAdAccounts()
      setIsLoading(false);
      setShowModal(false);
      navigate("/ad");
    } catch (e) {
      setIsLoading(false);
      console.log("Error!!");
      if(e.response.status === 500) {
        setStatus(false)
      }
    }
  };
  const adAccountActions = [
    {
      value: "Link",
      onClick: handleSave,
      loading: isLoading,
      icon: {
        name: "Link",
        color: "white",
      },
    },
  ];
  
  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Select Ad Accounts"}
        actions={adAccountActions}
        onClose={onClose}
      >
        <div className="w-[1000px]">
          <SyncAdAccountsTable
            adAccountsState={adAccountsState}
          />
        </div>
      </Modal>
    </>
  );
};
