import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAppContext } from "../context/Context";
import useUserPlan from "../hooks/useUserPlan"; // Assuming you export this from a hook file
import Loading from "../components/Loading";

const Subscribed = () => {
  const { userPlan } = useAppContext();
  const { userPlanLoading } = useUserPlan();

  if (userPlanLoading) {
    return <Loading />
  }

  return  <Outlet />

  // return !userPlan ? <Navigate to="/subscription" /> : <Outlet />;
};

export default Subscribed;
