import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import BigSidebar from "../../components/BigSidebar";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/Context";
import StoreModal from "../../components/StoreModalV2";
import { useErrorContext } from "../../context/ErrorContext";
import { useMediaQuery } from 'react-responsive'
import NavbarV2 from "../../components/NavbarV2";


const SharedLayout = () => {
  const { sidebar, userData, showModal } = useAppContext();
  const { pathname } = useLocation();
  const { status} = useErrorContext()
  const [showSidebar, setShowSidebar] = useState(true);
  
  const isDesktop  = useMediaQuery({ minWidth: 768 })
  const isMobile  = useMediaQuery({ maxWidth: 767 })
  const [expanded, setExpanded] = useState(isDesktop);

  // Automatically scrolls to top whenever pathname changes
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setExpanded(!showSidebar);
  }

  useEffect(() => {
    if(isDesktop) {
      setExpanded(true);
    } else if (isMobile) {
      setExpanded(false);
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!userData) {
    return <Navigate to={"/login"} />;
  }

  if(!status) {
    return <Navigate to={"/error"} />;
  }

  return (
    <div className="bg-[#F8F9FB]">
      {
        <StoreModal showModal={showModal} />
      }
      <div className="flex relative">
        {isMobile && <NavbarV2 toggleSidebar={toggleSidebar}/>}
        <BigSidebar
          showSidebar={showSidebar}
          expanded={expanded}
          setExpanded={setExpanded}
          isMobile={isMobile}
          sidebar={sidebar}
          setShowSidebar={setShowSidebar}
          />
        { isMobile && expanded &&
          <div className="bg-black opacity-30 absolute right-0 bottom-0 left-0 top-0 z-1" onClick={() => setExpanded(false)}>
            </div>
        }
        
        <div
          className={`${
            expanded && isDesktop
              ? "ml-[210px]"
              : isMobile
              ? "ml-[0px] mt-[50px]"
              : "ml-[70px] mt-[40px]"
          }  flex-[7] overflow-y-scroll`}
        >

            <Outlet context={[expanded]} />
          </div>
      </div>
    </div>
  );
};

export default SharedLayout;
