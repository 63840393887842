import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Icon from "../Icon";
import { useTableContext } from "../../context/TableContext";
import {
  imagyTextBodyTemplate,
  linkBodyTemplate,
  statusBodyTemplate,
} from "./Cells";

const SyncProductsTable = ({ productsState, setProductsState }) => {
  const {
    selectedItems,
    setSelectedItems
  } = useTableContext();

  const PriceBodyTemplate = (rowData) => {
    let text = "";
    let variants = rowData.variants;

    if (variants.length === 1) {
      text += variants[0].price; ;
    } else {
      text += "Multiple variants";
    }

    return (
      <div className="flex items-center gap-2 cursor-pointer" >
        <span className="font-bold">{text}</span>
      </div>
    );
  };

  return (
    <>
      <div className="my-3 rounded mx-4 shadow">
        <DataTable
          value={productsState.data}
          loading={""}
          stripedRows
          showGridlines
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          selection={selectedItems}
          onSelectionChange={(e) => {
            setSelectedItems(e.value);
          }}
          emptyMessage="No available data"
        >
          <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            field="product"
            header="Product"
            body={(rowData) =>
              imagyTextBodyTemplate(rowData.title, rowData.coverImage)
            }
          />
          <Column 
            field="price" 
            header="Price" 
            body={PriceBodyTemplate} 
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => statusBodyTemplate(rowData.status)}
          />
        </DataTable>
      </div>
    </>
  );
};

export default SyncProductsTable;
