import React, { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "./Icon";
import { useAppContext } from "../context/Context";
import NewsBtn from "./NewsBtn";
import { AlertTriangle } from 'lucide-react';
import useSubscriptionData from "../hooks/useSubscriptionData";
const SidebarContext = createContext();

const Sidebar = ({ children, expanded, setExpanded }) => {
  const { userData, subscriptionData } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  
  useSubscriptionData();
  const path = location.pathname;
  const navigateHome = () => navigate("/");
  const handleProfile = () => navigate("/profile");

  const goTo = (path) => navigate(path);
  return (
    <div
      className={`h-screen bg-white fixed z-[2]  border-r shadow-sm font-semibold transition	${expanded ? "w-[210px]" : "w-[70px]"
        }`}
    >
      <div
        className="absolute top-16 right-0 translate-x-1/2 -translate-y-1/2 bg-white p-1   rounded-full cursor-pointer shadow"
        onClick={() => setExpanded(!expanded)}
      >
        <Icon name={!expanded ? "ChevronLeft" : "ChevronRight"} size="11" />
      </div>
      <div
        className="flex items-center justify-center h-16 cursor-pointer py-4 "
        onClick={navigateHome}
      >
        <div className="h-8 flex gap-2 ">
          <img src="/images/logos/logo-black-short.jpeg" />
          <img
            src="/images/logo-black-long.png"
            className={`${!expanded && "hidden"} `}
          />
        </div>
      </div>
      <nav
        className={`flex gap-[30px] h-[calc(100%-70px)]  flex-col py-2  border-t-[1px] ${expanded ? "w-52" : "w-16"
          }`}
      >
        <SidebarContext.Provider value={{ expanded, setExpanded }}>
          <ul
            className={`flex-1 flex 2 flex-col px-3 ${expanded ? "w-52" : "w-16"
              }`}
          >
            {children}
          </ul>
        </SidebarContext.Provider>

        <div>

          <NewsBtn expanded={expanded} />
          <div onClick={() => goTo("workspace-selection")} className="flex cursor-pointer items-center">
            <div className={`flex items-center mx-auto bg-white rounded-md transition-all duration-300 ${expanded ? "w-[90%] p-2 my-2 border border-[#808080]/30" : "w-16 ml-4"}`}>
              {/* Icon Container */}
              <div className={` flex items-center justify-center  rounded-lg ${expanded ? "w-10 h-10" : "w-8 h-8"}`}>
                <img
                  src={userData?.workspace?.image}
                  className={`${expanded ? "w-7 h-7" : "w-7 h-7"} `}
                  alt="Icon"
                />
              </div>
              {expanded && (
                <div className="ml-4">
                  <h2 className="font-semibold text-gray-800 text-sm">{userData?.workspace?.name}</h2>
                  <p className="text-xs text-gray-500">Admin</p>
                </div>
              )}
            </div>
          </div>
          <div onClick={() => goTo("subscription")} className="flex items-center cursor-pointer justify-center">
            {expanded && subscriptionData?.status === "TRIAL" && (
              <span className="bg-yellow-300 text-yellow w-[90%] text-[11px] px-1.5 py-1.5 flex items-center gap-1 mx-2 rounded-md">
                <AlertTriangle size={14} className="text-yellow" />
                Free Trial - {Math.max(0, Math.ceil((new Date(subscriptionData.next_billing_time) - new Date()) / (1000 * 60 * 60 * 24)))} days left
              </span>
            )}
          </div>
          <div
            onClick={() => handleProfile()}
            className={`cursor-pointer flex w-[90%] mx-auto px-1 py-3 rounded-md ${path == "/profile" && "bg-blue-100"}`}
          >
            <div className="w-full flex items-center justify-center gap-1.5">
              <img
                className="w-6 h-6 rounded-full"
                src={`${userData.profileImage
                  ? userData.profileImage
                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  } `}
                alt="Jese"
              />
              <div
                className={`flex justify-between items-center gap-2 overflow-hidden transition-all ${expanded ? "w-full" : "hidden"
                  }`}
              >
                {
                  expanded && (
                    <div className="flex flex-col items-start gap-1">
                      <div className="font-extrabold text-xs">
                        {userData.fullname}
                      </div>
                      <div className="text-[9px] font-normal">{userData.email}</div>
                    </div>)
                }
                {
                  expanded &&
                  <div>
                    <Icon size="15" name="ChevronRight" color={"#697586"} />
                  </div>
                }
              </div>
            </div>
          </div>
          {/* <div
            onClick={() => window.open("/privacy-policy", "_blank")}
            className="cursor-pointer flex pl-6 p-3"
          >
            <Icon name="Shield" color={"#697586"} />
            <div
              className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
            >
              <div className="leading-4">Privacy policy</div>
            </div>
          </div> */}

          {/* <div
            onClick={() => handleLogout()}
            className=" cursor-pointer flex pl-6 p-3 "
          >
            <Icon name="LogOut" color={"#697586"} />
            <div
              className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
            >
              <div className="leading-4">Logout</div>
            </div>
          </div> */}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;

export function SidebarItem({
  icon,
  text,
  path,
  active,
  tabName,
  setTabName,
  role,
  isLocked,
}) {
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);
  const { userData } = useAppContext();
  const goTo = (path) => navigate(path);

  const handleClick = () => {
    if (isLocked) return;
    setTabName(text);
    goTo(path);
  };
  return (
    <>
      {userData.role === "publisher" &&
        ["Publishers", "Invitations"].indexOf(text) !== -1 ? (
        ""
      ) : (
        <div
          onClick={handleClick}
          className={`
          relative flex items-center py-2 px-3 my-1
          rounded-md cursor-pointer
          transition-colors group
          ${tabName === text ? "bg-blue-100 " : "hover:bg-blue-100 "}
      `}
        >
          <Icon
            name={isLocked ? "Lock" : icon}
            color={tabName === text ? "#0072ff" : "#697586"}
            size={30}
          />
          {expanded && (
            <span
              className={`overflow-hidden transition-all text-[16px]  leading-5 ${expanded ? "w-52 ml-3" : "w-0"
                }`}
            >
              {text}
            </span>
          )}

          {!expanded && (
            <div
              className={`
                absolute left-full rounded-md px-2 py-1 ml-6
                bg-[#ddebff]  text-sm
                invisible opacity-20 -translate-x-3 transition-all
                group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 
            `}
            >
              {text}
            </div>
          )}
        </div>
      )}
    </>
  );
}
