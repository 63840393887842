import React, { useContext } from 'react';
import { IntegrationContext, IntegrationContextProvider } from '../../context/IntegrationContext'; 
import EmptyIntegrations from './EmptyIntegrations';
import IntegrationsDashboard from './IntegrationsDashboard';
import Page from '../Page';
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading';



const IntegrationsComponent = () => {

  const { integrations, loading, error } = useContext(IntegrationContext);
  const navigate = useNavigate();
  const actions = [
    {
      name: "Create new",
      action: () => navigate("/integration/create"),
      type: "primary",
      icon: {
        name: "Link",
        color: "white",
      },
    },
  ]

  if(loading) {
    return <Loading />
  }

  // if(error) {
  //   return <p>Error: {error}</p>
  // }

  
  
  if(!integrations || integrations.length === 0) {
    return <EmptyIntegrations />
  } else {
    return (
      <Page title="Integrations Overviews" actions={actions}>
        <IntegrationsDashboard />
      </Page>
    )
  }

}


const Integration = () => {
  return (
    <IntegrationContextProvider>
      <IntegrationsComponent />
    </IntegrationContextProvider>
  )
}

export default Integration
