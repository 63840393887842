import { useNavigate } from "react-router";
import PayPalSubscriptionBtn from "../../pages/PayPalSubscriptionBtn";
import api from "../../utils/api";
import { useAppContext } from "../../context/Context";

import { IoCardOutline } from "react-icons/io5";
import Icon from "../Icon";
import "./index.css";

export default function FlashPlan() {
  const planId = process.env.REACT_APP_PAYPAL_DISCOUNTED_PLAN_ID;

  const navigate = useNavigate();
  const { setUserPlan } = useAppContext();
  const { userData } = useAppContext();

  const handleStripe = () => {
    const paymentLink = process.env.REACT_APP_DISCOUNTED_STRIPE_PAYMENT_LINK;
    const basicPlan = `${paymentLink}?client_reference_id=${userData._id}&prefilled_email=${userData.email}`;
    window.location.href = basicPlan;
  };
  const createSubscription = async (details) => {
    if (!details) return;
    // setSubscriptionLoading(true);
    try {
      const body = {
        planId: details.plan_id,
        subscriptionId: details.id,
        status: details.status,
      };

      const res = await api.post("/userPlan", body);
      setUserPlan(res.data.userPlan);
      navigate("/");
    } catch (error) {
      console.log(error);
    }

    // setSubscriptionLoading(false);
  };

  const handleApprove = (data, actions) => {
    return actions.subscription.get().then(async (details) => {
      console.log("Subscription approved: ", details);
      // Handle the subscription details on your server
      await createSubscription(details);
    });
  };

  const handleCancel = (data) => {
    // console.log("Subscription cancelled: ", data);
    // Handle the cancellation on your server
    // alert("Subscription cancelled.");
  };

  const handleError = (error) => {
    console.error("Subscription error: ", error);
    // Handle the error on your server

    alert("An error occurred during the subscription process.");
  };

  return (
    <div className="text-[#2c2e2f] flex flex-col gap-10 border border-gray shadow-lg rounded-lg p-6 w-full">
      <div className=" flex items-center justify-center flex-col font-medium text-center gap-3">
        <div className="text-[25px]">Entrepreneur Plan</div>
        <div className="bg-[#e2f3ff] gap-1 text-[12px] flex border border-[#89ccff] p-2 rounded-full text-[#0072ff] font-medium mx-2">
          <p>Flash Deliver offer | </p>
          <p className="font-bold">-80%</p>
        </div>
      </div>
      <div className="flex flex-col gap-2 ">
        {/*  */}
        <div className="ml-4 mb-4 ">
          <div className="flex items-baseline leading-4 gap-1">
            <p className="line-through	text-[20px]  text-[#7b8388]">$49</p>
            <p className="font-bold	text-[25px]">$9.89</p>
            <p>/ Month</p>
          </div>
          <div className="flex gap-1 text-center text-[#7b8388] ml-2">
            {" "}
            + One month free trial
          </div>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Up to $50k ad spend per month</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Agency Ad Account</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Ads Management</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Conversion API</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Profit Calculator</p>
        </div>
        {/* <div className="flex gap-2 font-medium ml-2">
                   <Icon name="Check" color='green' />
                   <p>1 Dashboard</p>   
                </div>
                <div className="flex gap-2 font-medium ml-2">
                   <Icon name="Check" color='green' />
                   <p>1 User</p>   
                </div>
                <div className="flex gap-2 font-medium ml-2">
                   <Icon name="Check" color='green' />
                   <p>1 Workspace</p>   
                </div> */}
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Standard API Access</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Dedicated Onboarding Team</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Priority Support</p>
        </div>
        <div className="flex gap-2 font-medium ml-2">
          <Icon name="Check" color="green" />
          <p>Unlimited future updates</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div
          onClick={handleStripe}
          className="w-full cursor-pointer rounded bg-blue-700 gap-2 text-white flex items-center justify-center p-3 text-l font-semibold"
        >
          <IoCardOutline color="white" size="24" />
          Start free trial
        </div>
        <PayPalSubscriptionBtn
          planId={planId}
          handleApprove={handleApprove}
          handleCancel={handleCancel}
          handleError={handleError}
        />
      </div>
    </div>
  );
}
