import React, { useState } from 'react';
import YoucanInputDomain from './YoucanInputDomain';
import Install from './Install';
import YoucanProducts from './YoucanProducts';
import ThankYouIntegration from '../Components/ThankYouIntegration';
import ProductTable from '../ProductTable';

const YoucanIntegration = ({ handleContinue, handleBack, activeStep= 1 }) => {
  const [domains, setDomains] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  return (
    <>
      {activeStep === 1 && (
        <Install
          setDomains={setDomains}
          domains={domains}
          loading={loading}
          setLoading={setLoading}
          setProducts={setProducts}
          handleContinue={handleContinue}
        />
      )}
      {activeStep === 2 && 
        (<ProductTable 
          shopifyProducts={products}
          domains={domains}
          handleContinue={handleContinue}
          platform={"YouCan"}
          />
        )}
      {activeStep === 3 && <ThankYouIntegration />}
    </>
  );
};

export default YoucanIntegration;
